<template>
    <CommonLayoutsVue layoutContentStyle="margin-top: 8rem">
        <template v-slot:child>
            <b-row style="max-width:375px" class="centered-container">
                <b-row class="mt-4">
                    <b-col class="header-container text-center mb-2">
                        <h2 class="centered-header">Update your password</h2>
                    </b-col>
                </b-row>
                <b-row class="centered-content">
                    <b-form class="customized-form w-100">
                        <div class="col-12">
                            <b-form-group label="" label-for="user-password">
                                <b-form-input id="user-password" :type="passwordType ? 'password' : 'text'" size="sm"
                                    placeholder="Enter new password"></b-form-input>
                                <img @click="viewPassword" style="position: absolute;top: 30%;right: 30px;"
                                    src="@/assets/zaajira-candidate/assets/icons/eye.svg" alt="" srcset="">
                            </b-form-group>
                        </div>
                        <div class="col-12">
                            <b-form-group label="" label-for="confirm-password">
                                <b-form-input id="confirm-password" :type="passwordType ? 'password' : 'text'" size="sm"
                                    placeholder="Re-enter new password"></b-form-input>
                                <img @click="viewPassword" style="position: absolute;top: 30%;right: 30px;"
                                    src="@/assets/zaajira-candidate/assets/icons/eye.svg" alt="" srcset="">
                            </b-form-group>
                        </div>
                        <div class="col-12 my-4 btn-flex-fd-row-jc-sb">
                            <span @click="goBack" class="go-back"
                                style="cursor: pointer; display:flex;align-items:center;color:#9C9CA4;">Back</span>
                            <b-button variant="primary" size="sm">Update password</b-button>
                        </div>
                    </b-form>
                </b-row>
            </b-row>
        </template>
    </CommonLayoutsVue>
</template>

<script>
import CommonLayoutsVue from '../../../components/Layouts/Common-Layouts.vue'

export default {
    name: "UpdatePassword",
    components: { CommonLayoutsVue },
    data() {
        return {
            passwordType: true,
        }
    },
    methods: {
        goBack() {
            this.$router.push({ path: '/' })
        },
        viewPassword() {
            this.passwordType = !this.passwordType
        },
    }

}
</script>

<style></style>